import Request from '@/services/Request';

export default class UserService {
	static registerUser(email, password) {
		return Request.request('POST', '/api/user', {
			email: email,
			password: password,
		});
	}
	static emailConfirmation(token) {
		return Request.request('POST', `/api/user/confirmation`, { token: token });
	}
	static authentication(email, password) {
		return Request.request('POST', '/api/auth', {
			email: email,
			password: password,
		});
	}
	static forgottenPassword(email) {
		return Request.request('POST', '/api/user/forgotten-password/request', { email });
	}
	static changePassword(token, password) {
		return Request.request('POST', '/api/user/forgotten-password/confirmation', { token, password });
	}

	static checkSession() {
		return Request.request('POST', '/api/auth/session');
	}

	static getAuthenticatedUser() {
		return Request.request('GET', '/api/user');
	}

	static getUsers() {
		return Request.request('GET', '/api/users');
	}
}
