<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'single-property-marketplace-carousel': true,
  }">

    <div class="d-flex top-nav slider" v-if="cities">
      <div class="container d-flex align-items-center premium-properties me-auto">
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.99316 7.99395C5.00458 7.99395 3.01599 7.99395 1.02741 8C0.892886 8 0.846096 7.96367 0.82855 7.8244C0.694028 6.86771 0.547808 5.90495 0.407438 4.94826C0.267067 4.07028 0.138394 3.19835 0.00972078 2.32037C-0.0253719 2.07211 0.0331159 1.89652 0.208579 1.78147C0.384043 1.66642 0.582901 1.68459 0.775911 1.83596C1.61229 2.48385 2.45451 3.1378 3.29673 3.79174C3.712 4.11266 4.07462 4.05211 4.36121 3.61009C5.06891 2.51413 5.77077 1.41817 6.47847 0.322204C6.67148 0.0194516 6.95807 -0.0713741 7.24466 0.0557819C7.36748 0.116332 7.45521 0.213213 7.5254 0.328259C8.23895 1.43633 8.9525 2.5444 9.66605 3.65853C9.81812 3.90073 10.0287 4.01578 10.3094 3.98551C10.4498 3.9734 10.5668 3.89468 10.672 3.80991C11.5026 3.16202 12.3389 2.52018 13.1695 1.8723C13.2689 1.79963 13.3625 1.72092 13.4912 1.70881C13.8011 1.69064 14.0409 1.9389 13.9941 2.25982C13.8889 2.99248 13.7719 3.73119 13.6666 4.46385C13.5262 5.4145 13.3859 6.37119 13.2397 7.32184C13.2104 7.50954 13.1753 7.69725 13.1578 7.88495C13.1461 7.96367 13.1168 7.98789 13.0466 7.98789C13.0057 7.98789 12.9706 7.98789 12.9297 7.98789C10.9528 7.99395 8.97005 7.99395 6.99316 7.99395Z"
              fill="#FCB745" />
          <path
              d="M6.9943 9.01143C8.93916 9.01143 10.884 9.01143 12.8289 9.00571C12.9715 9.00571 13 9.04571 13 9.17714C12.9886 9.54857 13 9.92 12.9943 10.2857C12.9943 10.7486 12.7433 11 12.2871 11C8.76236 11 5.23764 11 1.71863 11C1.25665 11 1.0057 10.7486 1.0057 10.2857C1.0057 9.90857 1.01141 9.52571 1 9.14857C1 9.02857 1.03422 9 1.14829 9C3.09886 9.01143 5.04943 9.01143 6.9943 9.01143Z"
              fill="#FCB645" />
        </svg>
        <div><span class="m-1">Premium</span>{{ $t('properties') }}</div>
      </div>
      <div class="property-carousel-nav-item col-auto" v-for="city in cities">
        <div @click="selectedCity = city.id" v-bind:class="{'selected-item': selectedCity === city.id}">{{ city.name }}</div>
      </div>
    </div>

    <div class="" v-if="filteredProperties">
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="property in filteredProperties" :key="property">
          <div class="slider_item">
            <PropertyAd
                :property="property"
                :id="property.id"
                :adtype="property.isVip ? 'premium' : 'regular'"
                :city="property.city.name"
                :district="property.district.name"
                :title="property.title"
                :amount="property.price"
                :images="property.images"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation>
            <template #next>
              <div class="buttons-left-right button-right">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1747_9026)">
                    <path d="M1 7L17 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M11 13L17 7L11 0.999999" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1747_9026 ">
                      <rect width="18" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </template>
            <template #prev>
              <div class=" buttons-left-right button-left">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1747_16541)">
                    <path d="M17 7L1 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M7 1L1 7L7 13" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1747_16541">
                      <rect width="18" height="14" fill="white" transform="translate(18 14) rotate(180)"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>
  </div>

</template>

