import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";

export default {
    name: 'IndexHeading',
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        PropertyAd

    },
    data() {
        return {
            propertySettings: {},
            cities: {},
            //Input models
            propertyLocation: 0,
            propertyType: 0,
            priceFrom: null,
            priceTo: null
        }
    },
    async mounted() {
        await this.$store.dispatch("property/getSettings");
        this.propertySettings = this.$store.state.property.settings ?? null;

        this.cities = await this.$store.dispatch("location/getCitiesMapped");
    },
    methods: {
        search() {
            const query = {};
            if (this.priceFrom && this.priceFrom !== '') {
                query.priceFrom = this.priceFrom;
            }
            if (this.priceTo && this.priceTo !== '') {
                query.priceTo = this.priceTo;
            }
            if (this.propertyType && this.propertyType !== '') {
                query.propertyType = this.propertyType;
            }
            if (this.propertyLocation && this.propertyLocation !== '') {
                query.city_id = this.propertyLocation;
            }

            this.$router.push({
                name: 'PropertySearchPage',
                query: query
            })
        },
        openAdvancedSearch() {
            this.$router.push({name: 'PropertySearchPage'});
            this.$store.dispatch('utils/toggleAdvancedSearch', {});
        },
    }
}