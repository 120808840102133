<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="d-md-none index-heading-container container">
      <div class="index-video-mobile">
        <video  muted  loop controls playsinline webkit-playsinline preload poster="/static/index-mobile-video-thumb.png" id="myVideo">
          <source src="/static/video/hero.mp4" type="video/mp4">
        </video>
      </div>
      <div class="index-heading-text">
        <div class="index-heading-title">
          {{ $t('find') }} <span>  {{ $t('your-place') }} </span> {{ $t('and-most') }}-<span>{{ $t('fresh-people') }}</span>
        </div>
        <div class="index-heading-description" v-if="false">
          {{ $t('index-heading-description') }}
        </div>
      </div>
      <div class="index-heading-search">
         <div class="input-item">
           <Dropdown :label="$t('property-type')" v-model="propertyType" :options="propertySettings.type" v-if="propertySettings" />
        </div>
        <div class="input-item" v-if="cities">
          <Dropdown :label="$t('city')" v-model="propertyLocation" :options="cities"/>
        </div>
         <div class="row g-0 input-item">
          <div class="col-6 pe-2 ">
            <InputField :label="$t('price-from')" :inputtype="'number'" v-model="priceFrom" :content="priceFrom" />
          </div>
          <div class="col-6 ps-2">
            <InputField :label="$t('price-to')" :inputtype="'number'" v-model="priceTo" />
          </div>
        </div>
        <div class="extended-search" @click="openAdvancedSearch()">

          {{ $t('advanced-search') }}
        </div>
        <div>
          <Button @click="search()" :size="'medium'" :theme="'solid-orange'" :text="$t('search')" :svg="'search-white'" />
        </div>
      </div>
    </div>
    <div class="d-none d-md-block index-heading-container">
      <video autoplay muted loop id="myVideo">
        <source src="/static/video/hero.mp4" type="video/mp4">
      </video>
      <div class="index-heading-text">
        <div class="index-heading-title">
          {{ $t('find') }} <span>  {{ $t('your-place') }} </span>
          <div>{{ $t('and-most') }}-<span>{{ $t('fresh-people') }}</span> </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="index-heading-description col-4" v-if="false">
            {{ $t('index-heading-description') }}
          </div>
        </div>

      </div>
      <div class="container">
        <div class="index-heading-search  d-flex justify-content-between align-content-center">
          <div class="input-item col-md-2">
            <Dropdown :label="$t('property-type')" v-model="propertyType" :options="propertySettings.type" v-if="propertySettings" />
          </div>
          <div class="input-item col-md-2">
            <Dropdown  :label="$t('city')" v-model="propertyLocation" :options="cities" v-if="cities" />
          </div>
          <div class=" input-item col-md-2">
            <InputField :label="$t('price-from')" :inputtype="'number'" v-model="priceFrom" :content="priceFrom" />
          </div>
          <div class="input-item col-md-2">
            <InputField :label="$t('price-to')" :inputtype="'number'" v-model="priceTo" :content="priceTo" />
          </div>

          <div class="extended-search col-md-1" @click="openAdvancedSearch()">
            {{ $t('advanced-search') }}
          </div>
          <div class="col-md-2 input-item">
            <Button @click="search()" :size="'medium'" :theme="'solid-orange'" :text="$t('search')" :svg="'search-white'" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

