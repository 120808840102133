import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";

export default {
    name: 'IndexOfferBanner',
    props: {
        washingmachine: {
            type: String,
            default: 'no'
        },
        fridge: {
            type: String,
            default: 'no'
        },
        oven: {
            type: String,
            default: 'no'
        },
        microwave: {
            type: String,
            default: 'no'
        },
        dishwasher: {
            type: String,
            default: 'no'
        },
        tv: {
            type: String,
            default: 'no'
        },
        sound: {
            type: String,
            default: 'no'
        },
        decorativelights: {
            type: String,
            default: 'no'
        },
        bed: {
            type: String,
            default: 'no'
        },
        sofa: {
            type: String,
            default: 'no'
        },
        kitchentable: {
            type: String,
            default: 'no'
        },
        coffeetable: {
            type: String,
            default: 'no'
        },


    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        PropertyAd

    },
    mounted() {
    }
}